// CoreUI and its default variables
@import 'variables';

.brand-sub {
  position: absolute;
  bottom: 10px;
  right: 53px;
  font-size: 0.65rem;
  text-transform: uppercase;

  &.test {
    right: 49px;
  }

  &.demo {
    right: 42px;
  }

  &.id {
    right: 61px;
  }
}

.header-brand {
  .brand-sub {
    bottom: -3px;
    right: -19px;
    font-size: 0.75rem;

    &.demo {
      right: -33px;
    }

    &.test {
      right: -24px;
    }

    &.id {
      right: -12px;
    }
  }
}

.without-login {
  &.header-brand {
    .brand-sub {
      bottom: -3px;
      right: -18px;
      font-size: 0.65rem;

      &.demo {
        right: -32px;
      }

      &.test {
        right: -22px;
      }

      &.id {
        right: -11px;
      }
    }
  }
}

.login-header {
  .brand-sub {
    bottom: -5px;
    right: -17px;
    font-size: 0.75rem;

    &.demo {
      right: -32px;
    }

    &.test {
      right: -23px;
    }

    &.id {
      right: -11px;
    }
  }
}

.sidebar-toggler {
  max-height: 2.5rem;
  line-height: 2.5rem;
  color: var(--cui-sidebar-nav-link-color, $gray-600);

  &:hover {
    color: var(--cui-sidebar-nav-link-hover-color, rgba(255, 255, 255, 0.87));
  }
}

.multi-select-container,
.form-multi-select-tag {
  font-size: $font-size-sm;
  min-height: 0;
  border-radius: 2px;
  background-color: hsl(0, 0%, 90%);
  .multi-select__indicator {
    padding: 4px 10px;
  }

  .multi-select__multi-value {
    margin: 0px 2px;
  }

  .multi-select__input-container {
    margin: 1px;
    padding: 1px;
  }

  .multi-select__control {
    min-height: auto;
    border: 1px solid var(--cui-form-select-border-color, #b1b7c1);

    &.multi-select__control--is-focused {
      border-color: var(--cui-form-select-focus-border-color, #809dcc);
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(1, 58, 152, 0.25);
    }
  }

  .multi-select__multi-value__label {
    padding: 1px 3px 1px 5px;
    font-size: inherit;
  }
}

.error {
  &.field {
    border: 1px solid var(--cui-danger);
  }

  &.badge {
    right: 57px;
  }
}

.rc-field {
  .input-group {
    .text-truncate {
      max-width: 60px;
    }
  }
}

.rc-multi-field {
  .rc-field {
    .delete-hidden {
      height: 0;
      padding: 0;
      margin: 0;
      visibility: hidden;
    }
    .rc-field-delete {
    }
    .rc-field-delete:hover {
      color: var(--cui-danger);
    }
  }
}

.accordion-button:not(.collapsed) .badge {
  visibility: hidden !important;
}

/* shadow quickfix */

.rc-content-nav-wrapper {
  &:after {
    height: 5px;
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -5px;
    left: 0px;
    right: 0px;
    background-color: white;
  }
}

.nav-tabs {
  border-bottom: 0;
  z-index: 1;
  .nav-link {
    background-color: #d8dbe0;
    border-width: 1px;
    border-bottom: 0px;
    border-color: var(--cui-gray-100);
    &.active {
      border-color: transparent;
      border-bottom: 0px;

      &:hover,
      &:focus,
      &:active {
        border-color: transparent;
      }
    }

    &:hover,
    &:focus,
    &:active {
      border-color: var(--cui-primary2);
      outline: 0;
    }
  }

  &.rc-content-nav {
    height: 2.5rem;
    padding-right: 60px;
    overflow: hidden;

    &.rc-expand {
      height: auto;
      padding-right: 160px;

      .nav-item {
        margin-bottom: 0.25rem;

        .nav-link {
          box-shadow: none !important;
        }
      }

      .rc-nav-expand {
        .nav-link {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
        .nav-link:active,
        .nav-link:focus,
        .nav-link:hover {
          border-bottom: 1px solid var(--cui-primary2);
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    &.rc-content-nav {
      height: auto;
      padding-right: inherit;
    }
  }
}

.rc-accordion-expand {
  .rc-div {
    border-right: 1px solid var(--cui-primary2);
  }

  button {
    background: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--cui-primary2);
    }
  }
}

.modal-view {
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  cursor: pointer;

  .custom-modal {
    cursor: default;
    z-index: $zindex-modal + 1;
    margin-top: 1%;
    padding: 2.5rem;
    border-radius: 7px;
    background-color: rgb(49, 49, 49);
    position: relative;

    .custom-icon {
      color: rgb(147, 147, 147);
    }
    .custom-icon:hover {
      color: rgb(195, 195, 195);
    }

    .modal-content {
      // width: fit-content;
      height: 100%;
    }
  }
}

.custom-datepick-popper {
  z-index: $zindex-modal !important;
}
