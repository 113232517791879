@import '~react-toastify/dist/ReactToastify.css';

$rt-namespace: 'Toastify';
// $rt-mobile: 'only screen and (max-width : 1000px)' !default;

// Example of overriding Toastify classes in SCSS
.#{$rt-namespace}__toast-container {
  // Custom container styles
  z-index: var(--toastify-z-index);
  transform: translate3d(0, 0, var(--toastify-z-index));
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  .#{$rt-namespace}__toast {
    // Custom toast styles
    color: black;
  }
  .#{$rt-namespace}__toast-body {
    // Custom toast body styles
    white-space: nowrap;
  }
}

// @media #{$rt-mobile} {
//   .#{$rt-namespace}__toast-container {
//     width: 1000px;
//     padding: 0;
//     left: env(safe-area-inset-left);
//     margin: 0;
//     &--top-left,
//     &--top-center,
//     &--top-right {
//       top: env(safe-area-inset-top);
//       transform: translateX(0);
//     }
//     &--bottom-left,
//     &--bottom-center,
//     &--bottom-right {
//       bottom: env(safe-area-inset-bottom);
//       transform: translateX(0);
//     }
//     &--rtl {
//       right: env(safe-area-inset-right);
//       left: initial;
//     }
//   }
// }
